import React, { useState, useContext, useEffect } from 'react'
import { 
    ActionsContent,
    CancelButton,
    Divider,
    FieldText,
    Container,
    SaveButton,
    TextInput,
} from './styles';

//Firebase and Context
import { AuthContext } from '../../contexts/AuthContext';
import { doc, updateDoc, collection, addDoc } from "firebase/firestore";
import db from '../../firebase/config';

//Custom Components
import { LoadingButton } from '../../Pages/ManageUsersPage/styles';
import ModalHeader from '../ModalHeader';
import ModalConfirm from '../ModalConfirm';

//Icons
import { AiOutlineCloseSquare } from 'react-icons/ai';
import { FiCheckSquare } from 'react-icons/fi';

//Utils
import { toast } from 'react-toastify'
import Modal from 'react-modal';
import DotLoader from "react-spinners/ClipLoader";
import Select from 'react-select';
import { addUserLogs } from '../../firebase/logs/users/addUserLogs';
import { addClientLogs } from '../../firebase/logs/clients/addClientLogs';
import { screenSizeValues } from '../../breakpoints';


const options = [
    "Contrato fechado",
    "Localidade diversa",
    "Endereço não encontrado",
    "Já tem advogado",
    "Não tem direito",
    "Não tem interesse",
]

const selectOptions = [
    {
        label: "Localidade diversa",
        value: "Localidade diversa",
    },
]

const ModalReport = ({ data, onClose, onSaved, screenWidth }) => {

    const { user, userData } = useContext(AuthContext);

    const [ selectedStatus, setSelectedStatus ] = useState("Localidade diversa");
    const [ description, setDescription ] = useState('');
    const [ loading, setLoading ] = useState(false);

    const [ modalConfirm, setModalConfirm ] = useState(false);
    const [ modalData, setModalData ] = useState('');

    const saveReport = async () => {
        setLoading(true);

        const dataInclusao = new Date(data.time);
        const date = new Date();

        const report = {
            operatorUid: user.uid,
            operatorName: userData.userName,
            clientCpf: data.cpf,
            clientName: data.nome,
            result: selectedStatus,
            description: description,
            routeDocId: 'off-route',
            dataRetorno: '',
            horaRetorno: '', 
            especie: data.especie,
            bairro: data[`bairro${data.validEnd}`],
            cidade: data[`cidade${data.validEnd}`],
            estado: data[`estado${data.validEnd}`],
            validEnd: data.validEnd,
            retornos: data.retornos, 
            tempoMapa: (date.getTime() - dataInclusao.getTime()),
            time: date.getTime(),
        };

        const docAdd = await addDoc(collection(db, "reports"), report);
        
        const docRef = doc(db, "clientes", data.cpf);
        await updateDoc(docRef, {
            selected: "",
            closeDate: date.getTime(),
            reportId: docAdd.id,
            operatorUid: user.uid,
            differentLocation: true,
            retornos: data.retornos,
        }).then(async () => {
            const logMessage = `${data.nome} foi atendido pelo operador ${userData.userName} com o resultado de ${selectedStatus} (Web)`
            const logMessageUser = `${userData.userName} atendeu o cliente ${data.nome} com o resultado de ${selectedStatus} (Web)`;

            await addClientLogs(data.cpf, logMessage)
            await addUserLogs(user.uid, logMessageUser)
            toast("Atendimento salvo com sucesso", { 
                style: {
                    background: 'var(--success)',
                    color: 'white',
                },
                progressStyle: {
                    background: 'white'
                },
                icon: <FiCheckSquare />
            })
            onSaved();
        }).catch(async (error) => {
            const logMessage = `${data.nome} falha ao salvar o atendimento feito pelo operador ${
                userData.userName} com o resultado de ${selectedStatus}: ${error.code} (Web)`
            const logMessageUser = `${userData.userName} falha ao salvar o atendimento do cliente ${
                data.nome} com o resultado de ${selectedStatus}: ${error.code} (Web)`

            await addClientLogs(data.cpf, logMessage)
            await addUserLogs(user.uid, logMessageUser)
            toast(`Erro ao salvar atendimento ${error.code}`, { 
                style: {
                    background: 'var(--red)',
                    color: 'white',
                },
                progressStyle: {
                    background: 'white'
                },
                icon: <AiOutlineCloseSquare />
            })
        })
        setLoading(false);
        onClose()
    }

    const customStyle = {      
        container: (base) => ({
            ...base,
            borderRadius: 40,
            color: 'var(--blue4)'
        }),
        control: (base) => ({
            ...base,
            padding: 3,
            borderRadius: 40,
            color: 'var(--blue4)'
        }),
        multiValue: (base) => ({
            ...base,
            borderRadius: 40
        }),
        singleValue: provided => ({
            ...provided,
            color: 'var(--blue4)'
        })
    } 

    const onChangeFilter = async (newValue) => {
        setSelectedStatus(newValue.value)
    }
    

    return (
        <Container>
            <ModalHeader title='Gerar atendimento' onClose={onClose} />
            <br />
            <FieldText>
                Resultado
            </FieldText>
            <Select 
                isClearable={false} 
                isSearchable={screenWidth >= screenSizeValues.md ? true : false} 
                defaultValue={{label: selectedStatus, value: selectedStatus}}
                closeMenuOnSelect={true} 
                options={selectOptions} 
                isMulti={false} 
                styles={customStyle}
                autoFocus={screenWidth >= screenSizeValues.md ? true : false}
                openMenuOnFocus={true}
                onMenuClose={() => {
                    if(screenWidth <= screenSizeValues.md){
                        window.scrollTo({ top: 0, behavior: 'smooth' })
                    }
                }}
                onChange={(item) => onChangeFilter(item)}
            />
            <br />
            <FieldText>
                Descrição
            </FieldText>
            <TextInput>
                <input 
                    name="description" 
                    onChange={event => setDescription(event.target.value)}
                    placeholder='Opcional'
                    onBlur={() => {
                        if(screenWidth <= screenSizeValues.md){
                            window.scrollTo({ top: 0, behavior: 'smooth' })
                        }
                    }}
                />
            </TextInput>

            <Divider />

            {loading
                ?<LoadingButton>
                    <DotLoader
                        size={20}
                        color={"var(--red)"}
                        loading={loading}
                        speedMultiplier={1.5}
                    />
                </LoadingButton> 
                : <ActionsContent>
                    <CancelButton onClick={onClose}>
                        <p>Cancelar</p>
                    </CancelButton>
                    <SaveButton onClick={() => {
                        setModalData({
                            text: 'Tem certeza que deseja salvar o atendimento?',
                            option: 'Sim'
                        })
                        setModalConfirm(true)
                    }}>
                        <p>Salvar</p>
                    </SaveButton>
                </ActionsContent>
            }
            <Modal
                isOpen={modalConfirm}
                onRequestClose={() => setModalConfirm(false)}
                overlayClassName="modal-overlay"
                className="modal-content-alert"
                contentLabel="Example Modal"
            >
                <ModalConfirm 
                    data={modalData} 
                    onCancel={() => {
                        setModalConfirm(false);
                    }}
                    onConfirm={async () => {
                        await saveReport();
                    }}
                />
            </Modal>
        </Container>
    )
}

export default ModalReport