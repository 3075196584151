import styled from "styled-components";

export const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  padding: 3% 8% 3% 8%;

  width: 100%;

  background: #de6161;
  box-shadow: 0px 7px 10px rgba(228, 73, 73, 0.25);
  border-radius: 100px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  text-align: center;

  /* 5 */

  color: #ffffff;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 13px;
  margin-top: 20px;
`;
