import React, { useEffect, useState, createContext } from "react";
import ClipLoader from "react-spinners/ClipLoader";

import { getAuth, onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { getDoc, doc } from "firebase/firestore";
import db from '../firebase/config';
import { ContainerLoading } from "./styles";

import { claims } from "../helpers/claims";
import { USER_TYPE } from "../constants/userType";
import { addUserLogs } from "../firebase/logs/users/addUserLogs";

export const AuthContext = createContext();

const defaultData = {
    user: null,
    data: '',
    role: '',
    loading: true
}

export const AuthProvider = (props) => {
    const { children } = props;
    const [ loggedUser, setLoggedUser ] = useState(defaultData);

    const auth = getAuth();  

    const isAdmin = loggedUser.role == USER_TYPE.ADMIN || loggedUser.role == USER_TYPE.ROOT ? true : false;
    const isManager = loggedUser.role == USER_TYPE.MANAGER ? true : false;

    const clearAuthContext = async () => {
        setLoggedUser({...defaultData, loading: false });     
        return
    }
    
    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                await getUserData(user);
            }else{
                clearAuthContext();
            }
        });        
    }, []);

    const firebaseLogin = async (email, password) => {
        return await signInWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
            // Signed in
                const user = userCredential.user;
            
                const { role } = await getUserData(user);

                return { success: true, role: role, error: null }
            })
            .catch((error) => {
                return { success: false, role: '', error: error }
            });
    };

    const getUserData = async (user) => {
        let userData = ''

        const profile = (await user.getIdTokenResult()).claims;

        const userDataRef = doc(db, "users", user.uid);
        const docUserData = await getDoc(userDataRef);

        const logMessage = `${docUserData.data().userName} acessou o sistema (Web)`;
            
        await addUserLogs(user.uid, logMessage)

        if (docUserData.exists()) {
            userData = {...docUserData.data(), uid: docUserData.id }

            if(docUserData.data().login){
                addDataIntoCache(user.email, docUserData.data().login);
            }
            
        } 

        if(claims.checkUserRole(profile) == USER_TYPE.MANAGER){
            const managerData = await getManagerData(user.uid);

            userData = {
                ...userData,
                ...managerData
            }
        }

        setLoggedUser({
            user: user,
            data: userData,
            role: claims.checkUserRole(profile),
            loading: false,
        });

        return { role: claims.checkUserRole(profile) }
    }

    //Cache do login
    const addDataIntoCache = (email, login) => {

        try {
            let savedData = JSON.parse(window.localStorage.getItem("logins"));

            const result = savedData.logins.find(x => x.email === email);

            if(result){
                if(result.login != login){
                    const array = savedData.logins;

                    const index = array.findIndex(x => x.email === email);

                    const array1 = array.slice(0, index);
                    const array2 = array.slice(index+1, array.length);

                    array1.push({
                        email: email,
                        login: login,
                    })

                    const data = {
                        logins: array1.concat(array2),
                    }
        
                    window.localStorage.setItem('logins', JSON.stringify(data));
                }
            }else{
                const array = savedData.logins;

                array.push({
                    email: email,
                    login: login,
                })

                const data = {
                    logins: array,
                }
    
                window.localStorage.setItem('logins', JSON.stringify(data));
            }

            
            
        } catch {
            
            const data = {
                logins: [
                    {
                        email: email,
                        login: login,
                    }
                ],
            }

            window.localStorage.setItem('logins', JSON.stringify(data));
        }
    };

    const getManagerData = async (uid) => {        
        const docRef = doc(db, 'managers', uid)
        const docManager = await getDoc(docRef);

        if (docManager.exists()) {
            return docManager.data();
        }else{
            return {
                operatorsArray: [],
                mapsArray: [],
                tokens: []
            }
        }
    }

    if(loggedUser.user != null && loggedUser.data == ''){
        return <ContainerLoading>
            <ClipLoader
                size={80}
                color="#DE6161"
                loading={true}
                speedMultiplier={1.5}
            />
        </ContainerLoading>
    }

    return (
        <AuthContext.Provider 
            value={{ 
                //System
                firebaseLogin,
                user: loggedUser.user, 
                role: loggedUser.role,
                isAdmin: isAdmin,
                isManager: isManager,
                loading: loggedUser.loading,
                clearAuthContext,
                
                //User data
                userData: loggedUser.data,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}