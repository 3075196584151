import React, { useState, createContext } from "react";

export const ClientsContext = createContext();

export const ClientsProvider = (props) => {
    const { children } = props;

    const [ mapClients, setMapClients ] = useState('');
    const [ mapClientsUser, setMapClientsUser ] = useState('');
    
    const [ attendClients, setAttendClients ] = useState('');
    const [ expiredReturns, setExpiredReturns ] = useState('');
    const [ returnMapClients, setReturnMapClients ] = useState('');
    const [ returnsUser, setReturnsUser ] = useState('');

    const [ mapRemovedClients, setMapRemovedClients ] = useState('');
    const [ totalClients, setTotalClients ] = useState('');

    const clearClientsContext = async () => {
        setMapClients('');
        setMapClientsUser('');
        setAttendClients('');
        setExpiredReturns('');
        setReturnMapClients('');
        setReturnsUser('');
        setMapRemovedClients('');
        setTotalClients('');
        return
    }

    return (
        <ClientsContext.Provider 
            value={{ 
                mapClients: mapClients,
                setMapClients,

                mapClientsUser: mapClientsUser,
                setMapClientsUser,

                attendClients: attendClients,
                setAttendClients,
                expiredReturns: expiredReturns,
                setExpiredReturns,
                returnMapClients: returnMapClients,
                setReturnMapClients,
                returnsUser: returnsUser,                
                setReturnsUser,

                mapRemovedClients: mapRemovedClients,
                setMapRemovedClients,

                totalClients: totalClients,
                setTotalClients,

                clearClientsContext,
            }}
        >
            {children}
        </ClientsContext.Provider>
    )
}