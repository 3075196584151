import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100dvh;
    width: 100%;

    align-items: center;
    justify-content: center;
    align-self: center;
    background: ${props => props.background ? props.background : 'transparent'};
`;